<template>
  <div>
    <header-view></header-view>
    <div class="content">
      <div v-if="cardLoading">
        <div class="title">
          {{ content.title }}
        </div>
        <div class="time">发布时间：{{ content.updateTime }}</div>
        <div class="line"></div>
        <div class="cen" v-html="content.content"></div>
      </div>

      <el-empty v-if=" !cardLoading" description="暂无数据"></el-empty>
    </div>
    <footer-view></footer-view>
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView";
import FooterView from "@/components/FooterView";
import { navigation } from "@/api/index";
export default {
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      type: "",
      content: "",
      cardLoading:false
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.school(); // 获取该路由页面内容的方法
  },
  watch: {
    $route(to, from) {
      // console.log(to)
      this.type = this.$route.query.type;
      this.school();
    },
  },
  methods: {
    // 列表
    school() {
      navigation(this.type)
        .then((res) => {
          console.log(res);
          if(res.data.length != 0){
          this.content = res.data[0];
          this.cardLoading = true;
          }else{
            this.cardLoading = false;
          }
        })
        .catch(() => {
          this.cardLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .content {
    width: 100% !important;
  }
}
.content {
  height: calc(100vh - 100px);
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding: 20px 0;
  box-sizing: border-box;
  .title {
    display: flex;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
  }
  .time {
    display: flex;
    justify-content: center;
    font-size: 16px;
    margin-top: 20px;
  }
  .line {
    height: 2px;
    background-color: rgb(240, 229, 229);
    margin: 25px 0;
  }
  .cen {
    padding: 0 25px;
    box-sizing: border-box;
  }
}
</style>
